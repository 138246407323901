import React from 'react'


const SplashScreen = () => {

    return (
        <div>
            SplashScreen
        </div>
    )
}

export default SplashScreen