import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import SplashScreen from '../pages/splash'


const AppLayout = () => {

    return (
        <div>
            <BrowserRouter>
				<div>
					<div>
						<Route exact path='/' render={SplashScreen}/>
					</div>
				</div>
			</BrowserRouter>
        </div>
    )
}

export default AppLayout